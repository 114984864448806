import React, { FC, useState, FormEvent } from 'react'
import styled from 'styled-components'
import { Redirect, useHistory } from 'react-router-dom'
import { Button, Switch, TextField } from '@material-ui/core'
import Page from 'core/Page'
import { Space, theme, Spinner, Text, Row } from 'ui'
import useStore from 'store'
import { useInput } from 'utils/hooks'
import { Path } from 'core/Routes'
import { useTranslation } from 'react-i18next'
import { Send, LiveHelp } from '@material-ui/icons'
import logoIcon from 'assets/icons/Logo.svg'
import coloredMekomeLogo from 'assets/coloredMekomeLogo.svg'
import Grow from 'ui/Grow'
interface ButtonProps {
  opacity?: number
}
const NO_USER = "Can't find user"
const INCORRECT_STRING = "The string supplied did not seem to be a phone number."

const SwitchComponent = styled(Switch)`

.MuiSwitch-colorPrimary{
    color:${theme.color.budgetPurple};
}
.MuiSwitch-track{
    background-color:${theme.color.budgetPink};
}
`
const StyledSend = styled(Send)`
  color:white;
  transform: rotate(180deg);
`
const StyledText = styled(Text)`
  @media (max-width: 768px) {
    font-size: 15px;
  }
  cursor: pointer;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const StyledPage = styled(Page)`
  background-color:${theme.color.loginBackground};
  height:100%;
  width:100%;
  justify-content:center;
  align-items:center;
  `
const StyledTextField = styled(TextField)`

  background: ${theme.color.mercury};
  border-radius: 5px;
  .MuiInputBase-input::placeholder {
    @media (max-width: 768px) {
    font-size: 13px;
  }
    color: black;
    opacity: 0.5;
    font-size:16px;
  }
  font-size:16px;
  `
const StyledButton = styled(Button) <ButtonProps>`
  height:50px;
  background-color: ${theme.color.cerulean};
  border-radius: 5px;
  opacity:${p => p.opacity ? p.opacity : null};
  color:white;
  :hover {background-color: ${theme.color.cerulean};	}
`
const Content = styled.div`
  width:28vw;
  display:flex;
  flex-direction:column;
  padding:2rem 3rem;
  background-color:${theme.color.whiteBackground};
  justify-content:center;
  border-radius:8px;
  box-shadow: ${theme.color.darkBlue + 10} 0px 0px 40px 20px;
  @media (max-width: 768px) {
    width: 85vw;
  }
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`
enum Mode {
  phone = 'phone',
  code = 'id',
}
const LoopInput: React.FC<{ mode: Mode, ref: any, onChange: () => {} }> = React.memo(React.forwardRef(({ mode, onChange }, ref) => {
  const { t } = useTranslation('auth')
  const phoneNumber = useInput({
    placeholder: t`phoneOrEmail`,
    onChange: onChange,
  })

  const code = useInput({
    placeholder: t('verify_numbers', { numbers: 'XXXXXX' }),
    onChange: onChange,
    // value: phoneText
  })
  // return <input type='text' ref={null}/>
  return <StyledTextField
    autoFocus
    variant='outlined'
    inputRef={ref}
    {...(mode === Mode.phone ? phoneNumber : code)}
  />
}))
const INCORRECT_CODE = (code: string) => `Could not register user,the verificaion code ${code} is wrong, this was try#`
const Failed_CODE = "Could not register user - no verificaion code exists for user with telephpne/email:"

const Login: FC = () => {
  const { user } = useStore()
  const [mode, setMode] = useState<Mode>(Mode.phone)
  const tempTokenRef = React.useRef('')//ref for temp token generated by otp1
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const { t } = useTranslation('auth')
  const [isLoading, setIsLoading] = useState(false)
  const h = useHistory()
  const [text, setText] = useState('')
  const [phoneText, setPhoneText] = useState('')
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const handleChange = React.useCallback((event: any) => {
    setLoginErrorMessage('')
    if (mode === Mode.phone) {
      setText((event?.target?.value || '') as string)
    } else {
      setPhoneText((event?.target?.value || '') as string)
    }
  }, [mode])

  const phoneNumber = useInput({
    placeholder: t`phoneOrEmail`,
    onChange: handleChange,
    value: text
  })

  const code = useInput({
    placeholder: t('verify_numbers', { numbers: 'XXXXXX' }),
    onChange: handleChange,
    value: phoneText
  })

  const sendDisabled = (mode === Mode.phone
    ? (phoneNumber.value && phoneNumber.value != '' ? 1 : 0.3) : (code.value && code.value != '' ? 1 : 0.3))

  const changePhone = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    setLoginErrorMessage('')
    setText('')
    setMode(Mode.phone)

  }
  const handleOk = async (event: FormEvent, sendAgain?: boolean) => {
    event.preventDefault()
    if (mode === Mode.phone || sendAgain) {
      if (phoneNumber.value) {
        let res = await user.sendPhoneOrEmail(phoneNumber.value)
        if (res.error === NO_USER) {
          res.error = t`noUser`
        } else if (res.error === INCORRECT_STRING) {
          res.error = t`phoneError`
        }
        if (res.error) {
          setLoginErrorMessage(res.error)
        } else {
          setMode(Mode.code)
          tempTokenRef.current = res?.data || ''
          if (inputRef.current) {
            inputRef.current.value = ''
          }
        }
      }
    }
    else {
      if (code.value) {
        let res = await user.sendVerificationCode(code.value, tempTokenRef.current)
        if (res.indexOf(Failed_CODE) != -1) {
          res = `${t`codeFailed`} ${user.phone}`
        } else if (res.indexOf(INCORRECT_CODE(code.value)) != -1) {
          res = t`incorrectCode`
        }
        if (res) {
          setLoginErrorMessage(res)
        }
      }
    }
    setIsLoading(false)
  }
  // if (user.hasToken) return <Redirect to={Path.HOME} />
  return (
    <StyledPage flex={true} >
      <Content>
        <Row justify='center'>
          <img src={coloredMekomeLogo} />
        </Row>
        <div style={{ padding: '2rem 0' }}>

          <Text align='center' size='lg'>{t`welcomeToMekome`}</Text>
          <Text align='center' size='xl' weight='bold'>ממשק מועצה אזורית</Text>
          <Space height={1} />
          {(mode === Mode.phone) ? (
            <Text textcolor={theme.color.darkGray} weight='light' align='center'>{t`loginDescription`}</Text>
          ) : (
              <Text textcolor={theme.color.darkGray} weight='light' align='center'>{t('loginCodeDescription')}</Text>
            )
          }
        </div>
        <Form onSubmit={handleOk}>
          {mode === Mode.phone && phoneNumber.value && phoneNumber.value != '' &&
             <Text
             size={'sm'}
             textcolor={theme.color.mekomiBlack}
             weight='normal'>
              {t`phoneOrEmail`}
            </Text>
          }
          {mode === Mode.code && code.value && code.value != '' &&
            <Text
            size={'sm'}
            textcolor={theme.color.mekomiBlack}
            weight='normal'>
              {t('verify_numbers', { numbers: 'XXXXXX' })}
            </Text>

          }
          <InputContainer>
            {isLoading ? <Spinner /> :
              <>
                {/* <StyledTextField
                  autoFocus
                  variant='outlined'
                  {...(mode === Mode.phone ? phoneNumber : code)}
                /> */}
                <LoopInput mode={mode} ref={inputRef} onChange={handleChange} />
              </>
            }
          </InputContainer>
          <Space height={1} />
          {mode === Mode.code && (
            <>
              <Row>
                <Button onClick={(e) => handleOk(e, true)}>
                  <StyledText
                    textcolor={theme.color.budgetBlue}
                    size={'lg'}
                  >{t`budget:newCode`}</StyledText>
                </Button>
                <Text
                  textcolor={theme.color.black}
                  size={'lg'}
                >{' | '}</Text>
                <Button onClick={changePhone}>
                  <StyledText
                    textcolor={theme.color.budgetBlue}
                    size={'lg'}
                  >{t`budget:anotherPhone`}</StyledText>
                </Button>
              </Row>
              <Space height={1} />
            </>
          )}
          {loginErrorMessage && (
            <>
              <Text
                textAlign={'center'}
                opacity={0.6}
                textcolor={'red'}
                weight='normal'>
                {loginErrorMessage}
              </Text>
              <Space height={1} />
            </>
          )}
          <StyledButton disabled={isLoading} type="submit" variant='contained' opacity={sendDisabled}>
            <Text
              size={'md'}
              textAlign={'center'}
              textcolor={'white'}
              weight='normal'>
              {mode === Mode.phone ? t`send` : 'כניסה למערכת'}
            </Text>
            {mode === Mode.phone && (
              <>
                <Space width={0.7} />
                <StyledSend />
              </>
            )}
          </StyledButton>
        </Form>
        <Space height={1} />
      </Content>
    </StyledPage>
  )
}

export default Login
