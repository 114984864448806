import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { AppBar, Toolbar, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import logoIcon from 'assets/icons/Logo.svg'
import userIcon from 'assets/icons/UserLogo.svg'
import { Space, Image, theme, Row, Button } from 'ui'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import packageJson from '../../../package.json'
import AddIcon from '@material-ui/icons/Add';

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  background-color: var(--dark-back);
  color: var(--white);
`
const StyledTopbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  color: var(--white);
`
const UserIcon = styled(Image)`
  padding: 0 20px 0 20px;
`
const UserText = styled.div`
 font-size: ${theme.fontSize.small};
`
const Logo = styled.img`
`
const StyledLogout = styled.div`
  display:inline-flex;
  border-bottom: 1px solid white;
  cursor:pointer;
`
const NewMessageButton = styled(Button)`
  background-color:${theme.color.blandGray};
  color:${theme.color.black};
  align-items:center;
  justify-content:center;
`
const Appbar: FC = () => {
  const { t } = useTranslation('app')
  const { user, members, orgs } = useStore()

  return (
    <StyledAppBar id='app-bar' position='static'>
      <StyledTopbar>
        footer
      </StyledTopbar>
    </StyledAppBar>
  )
}

export default observer(Appbar)
