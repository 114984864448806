import { observable, action, computed, makeObservable, runInAction } from 'mobx'
import api from 'service/api'
import ls from 'local-storage'
import type { Info, SendApiUserReq, Community } from 'utils/types'
import type { User, AppType } from 'utils/models'
import i18next from 'i18next'
import { enUS, he } from 'date-fns/locale'
import { storageKeys } from 'utils/vls'


class UserStore {

  constructor() {
    this.restore()
    makeObservable(this, {
      phone: observable,
      info: observable,
      email: observable,
      user: observable,
      isAuth: observable,
      jwt: observable,
      loading: observable,
      userListTableView: observable,
      concileUsers: observable,
      communitiesLoading: observable,
      communities: observable,
      labels: observable,
      userProfileOpen: observable,
      hasToken: computed,
      credentials: computed,
      token: computed,
      name: computed,
      dateFnsLocal: computed,
      openUserProfile: action,
      closeUserProfile: action,
      toggleUserProfile: action,
      setCommunitiesLoading: action,
      setPhone: action,
      setEmail: action,
      setUser: action,
      setIsAuth: action,
      login: action,
      logout: action,
      getAsApiUserReq: action,
      setJwt: action,
      initInfo: action,
      setLoading: action,
      initCommunities: action,
      sendVerificationCode: action,
      openUserListTable: action,
    })
  }

  restore = () => {
    const user: User | undefined = ls(storageKeys.user) as (User | undefined)
    if (user) this.login(user)
    const isAuth = ls(storageKeys.is_auth) as (boolean | undefined)
    if (isAuth) this.setIsAuth(isAuth)
    const jwt = ls(storageKeys.token) as (string | undefined)
    if (jwt) this.setJwt(jwt)
  }

  info: Info | null = null
  phone: string = ''

  email: string = ''

  user: User

  loading: boolean = true
  isAuth = false
  jwt: string | null = null

  userListTableView: boolean = false
  concileUsers: any = []

  communities: Community[] = []
  labels: string[] = []
  get hasToken() { return !!this.user?.user_token }

  get credentials() { return { id: this.user?.user_id, token: this.user?.user_token } }

  get token() { return this.user?.user_token }

  get name() { return `${this.user.given_name} ${this.user.family_name}` }

  get dateFnsLocal() { return i18next.language === 'he' ? he : enUS }

  userProfileOpen: boolean = false
  communitiesLoading: boolean = false

  setCommunitiesLoading = (bool: boolean) => {
    this.communitiesLoading = bool
  }

  openUserListTable = async(bool: boolean, concileNumber: string) => {
    this.userListTableView = bool;

    if (bool) {
      const res = await api.getConcileUsers(concileNumber)
      this.concileUsers = res  
    }
  }

  setLoading = (bool: boolean) => {
    this.loading = bool
  }
  openUserProfile = () => {
    this.userProfileOpen = true
  }
  closeUserProfile = () => {
    this.userProfileOpen = false
  }
  toggleUserProfile = () => {
    this.userProfileOpen = !this.userProfileOpen
  }
  setPhone = async (phone: string) => {
    this.phone = phone
  }
  setEmail = async (email: string) => {
    this.email = email
  }
  setUser = async (user: User) => {
    this.login(user)
  }


  setIsAuth = (state: boolean) => {
    ls(storageKeys.is_auth, state)
    this.isAuth = state
  }

  sendVerificationCode = async (code: string, tempToken: string) => {
    let user
    if (this.phone != '') {
      user = await api.checkCode(code, tempToken)
    } else {
      user = await api.checkCode(code, tempToken)
    }
    if (user?.success === true) {
      const serverUser = user?.data?.user
      this.setJwt(user?.data?.token)
      let locUser: User = {
        emails: serverUser.emails,
        family_name: serverUser.familyName,
        given_name: serverUser.givenName,
        nick_name: serverUser.nickName,
        phones: serverUser.phoneNumbers,
        user_token: serverUser.user_token,
        user_id: serverUser.userId,
      }
      this.communities = []
      this.setUser(locUser)
      await this.initInfo()
      this.setIsAuth(true)
      return ''
    }
    else {
      return user.error || ''
    }

  }

  login = (user: User) => {
    ls(storageKeys.user, user)
    this.user = user
  }

  sendPhoneOrEmail = async (input: string) => {
    const res = await api.getCode(input)
    if (res.success) {
      if (input.indexOf('@') == -1)
        this.setPhone(input)
      else {
        this.setEmail(input)
      }
    }
    return res
    // return res.error || ''
  }


  logout = () => {
    const res = api.logout()
    ls.remove(storageKeys.user)
    ls.remove(storageKeys.currentCommunityId)
    ls.remove(storageKeys.currentGroupId)
    ls.remove(storageKeys.currentTab)
    ls.remove(storageKeys.LANG)
    this.phone = ''
    this.email = ''
    this.setIsAuth(false)
    this.user = null;
    this.setLoading(true)
    this.setJwt('')
    this.closeUserProfile()
  }

  getAsApiUserReq(givenUser: User) {
    let user: SendApiUserReq = {
      id: 1,
      user: givenUser.user_id,
      given: givenUser.given_name,
      family: givenUser.family_name,
      nick: givenUser.nick_name,
      phones: givenUser.phones,
      emails: givenUser.emails
    }
    return user
  }
  setJwt(token: string) {
    ls(storageKeys.token, token)
    this.jwt = token;
  }
  async initInfo() {
    const res = await api.getInfo()
    if (res) {
      this.info = res
    }
  }
  async initCommunities() {
    try {
      this.setCommunitiesLoading(true)
      const communitiesRes = await api.getCommunities()
      if (communitiesRes) {
        runInAction(() => {
          //filter communities without labels
          this.communities = communitiesRes
        })
      }

      const labelsRes = await api.getLabels()
      if (labelsRes) {
        runInAction(() => {
          this.labels = (labelsRes)
        })
      }

    } catch (e) {
      console.log('initialize communities/labels error', e)
    }
    this.setCommunitiesLoading(false)
  }
  async init() {
    await this.initInfo()
    // await this.initCommunities()
    this.setLoading(false);
  }
}

const user = new UserStore()
export default user
